import { Component } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";

const SKIP_NAV_HASH = "#skip-nav" as const;

@Component({
  standalone: true,
  selector: "standard-skip-nav-link",
  template: `<a [href]="skipNavUrl">Skip to content</a>`,
  styles: `
    :host > a {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      position: absolute;

      &:focus {
        padding: 1rem;
        position: fixed;
        top: 10px;
        left: 10px;
        background: white;
        z-index: 940;
        width: auto;
        height: auto;
        clip: auto;
      }
    }
  `,
})
export class StandardSkipNavLinkComponent {
  constructor(private router: Router) {
    this.updateSkipNavUrl(window.location.pathname);
    router.events.pipe(takeUntilDestroyed()).subscribe((event): void => {
      if (event instanceof NavigationEnd) {
        this.updateSkipNavUrl(event.urlAfterRedirects);
      }
    });
  }

  skipNavUrl: string = SKIP_NAV_HASH;

  updateSkipNavUrl(urlAfterRedirects: string) {
    const newUrl = urlAfterRedirects.split("#")[0];
    this.skipNavUrl = `${newUrl}${SKIP_NAV_HASH}`;
  }
}
