import { NgTemplateOutlet } from "@angular/common";
import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import invariant from "tiny-invariant";
import { StandardNavLinkDropdownComponentContext } from "../standard-nav-link-dropdown/standard-nav-link-dropdown.component";

export type StandardNavSideActionsContext =
  StandardNavLinkDropdownComponentContext;

@Component({
  standalone: true,
  selector: "standard-nav-side",
  templateUrl: "./standard-nav-side.component.html",
  styles: `
    ::ng-deep standard-nav-side .nav-item .nav-link {
      &:not(.active):hover {
        background-color: var(--bs-tertiary-bg);
      }
    }

    ::ng-deep
      standard-nav-side
      > div
      > .nav
      > .nav-item
      > standard-nav-link-dropdown
      > div:has(standard-collapser)
      > .nav-link {
      background: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
      position: sticky;
      top: -14px;
      z-index: 7;
    }

    ::ng-deep
      .nav-item:not(:has(.nav-item)):has(
        standard-nav-link-dropdown > standard-nav-link > .nav-link.active
      ) {
      position: sticky;
      top: calc(
        (var(--bs-body-font-size) * var(--bs-body-line-height)) +
          (var(--bs-nav-link-padding-y) * 2)
      );
      z-index: 6;
    }
  `,
  imports: [NgTemplateOutlet],
})
export class StandardNavSideComponent {
  @Input() styleClass: string | undefined;
  @Input() headerStyleClass: string | undefined;
  @Input() startActionsStyleClass: string | undefined;
  @Input() endActionsStyleClass: string | undefined;

  @ContentChild("header", { static: false, descendants: false })
  headerTemplate: TemplateRef<never> | null = null;

  @ContentChild("startActions", { static: false, descendants: false })
  startActionsTemplate: TemplateRef<StandardNavSideActionsContext> | null =
    null;

  @ContentChild("endActions", { static: false, descendants: false })
  endActionsTemplate: TemplateRef<StandardNavSideActionsContext> | null = null;

  @ViewChild("contentScroller", { static: true }) contentScroller:
    | ElementRef<HTMLElement>
    | undefined;

  public scrollActiveNavItemIntoView(scrollBehavior: ScrollBehavior) {
    invariant(this.contentScroller);

    const scrollerEl = this.contentScroller.nativeElement;
    const activeNavItem = scrollerEl.querySelector(".nav-link.active");
    if (!activeNavItem) {
      return;
    }

    let topmostLiWithinThisUl: Element | undefined;
    let currentEl: Element | null = activeNavItem;
    while (currentEl) {
      if (currentEl.tagName === "LI") {
        topmostLiWithinThisUl = currentEl;
      }

      currentEl = currentEl.parentElement;
    }

    const scrollToEl = topmostLiWithinThisUl ?? activeNavItem;
    scrollToEl.scrollIntoView({
      behavior: scrollBehavior,
      block: "nearest",
      inline: "nearest",
    });
  }
}
