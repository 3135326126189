<standard-skip-nav-link />

@if (transitionState === "idle" && widthCategory !== undefined) {
  <div
    class="flex-grow-1 flex-shrink-1 overflow-hidden d-flex flex-column position-relative"
  >
    <div class="overflow-hidden d-flex flex-grow-1">
      @if (hasNavSide) {
        <div
          [attr.data-bs-theme]="sideColorMode"
          class="flex-grow-0 flex-shrink-0 bg-body text-body border-end"
          [@navSideCollapserContent]="{
            value: navSideOverlayed
              ? 'void'
              : navSideExpanded
                ? 'expanded'
                : 'collapsed'
          }"
          style="width: var(--bs-w-sidenav)"
        >
          <ng-container *ngTemplateOutlet="navSideContent" />
        </div>
      }

      <div class="d-flex flex-column flex-grow-1">
        @if (
          navTopTemplate ||
          navTopLogoTemplate ||
          navTopStartActionsTemplate ||
          navTopMiddleLogoTemplate ||
          navTopEndActionsTemplate
        ) {
          <div
            [attr.data-bs-theme]="topColorMode"
            [class]="topStyleClass ?? ''"
          >
            <standard-nav-top>
              <ng-container
                *ngTemplateOutlet="
                  navTopTemplate;
                  context: {
                    registerNavTemplates: registerNavTemplates.bind(this)
                  }
                "
              />

              @if (navTopLogoTemplate || hasNavSide) {
                <ng-template #logo>
                  <ng-container
                    *ngTemplateOutlet="
                      navTopLogoTemplate;
                      context: {
                        hasNavSide: hasNavSide,
                        navSideExpanded: navSideExpanded,
                        toggleNavSide: toggleNavSide.bind(this)
                      }
                    "
                  />
                </ng-template>
              }

              @if (
                widthCategory !== undefined &&
                widthCategory > topStartToSideStartMaxSize &&
                navTopStartActionsTemplate
              ) {
                <ng-template #startActions>
                  <ng-container
                    *ngTemplateOutlet="
                      navTopStartActionsTemplate;
                      context: { variant: 'top-nav' }
                    "
                  />
                </ng-template>
              }

              @if (navTopMiddleLogoTemplate) {
                <ng-template #middleLogo>
                  <ng-container
                    *ngTemplateOutlet="
                      navTopMiddleLogoTemplate;
                      context: { variant: 'top-nav' }
                    "
                  />
                </ng-template>
              }

              @if (
                widthCategory !== undefined &&
                widthCategory > topEndToSideEndMaxSize &&
                navTopEndActionsTemplate
              ) {
                <ng-template #endActions>
                  <ng-container
                    *ngTemplateOutlet="
                      navTopEndActionsTemplate;
                      context: { variant: 'top-nav' }
                    "
                  />
                </ng-template>
              }
            </standard-nav-top>
          </div>
        }

        <div
          #contentScroller
          class="d-flex flex-column flex-grow-1 overflow-y-auto scroll-container"
        >
          <main class="flex-grow-1">
            <standard-skip-nav-content />
            <ng-content />
          </main>

          @if (navBottomTemplate) {
            <div [attr.data-bs-theme]="bottomColorMode">
              <ng-container *ngTemplateOutlet="navBottomTemplate" />
            </div>
          }
        </div>
      </div>
    </div>

    <div
      [@navSideOverlayBg]="{
        value: !navSideOverlayed
          ? 'void'
          : navSideExpanded
            ? 'expanded'
            : 'collapsed'
      }"
      class="side-overlay position-fixed top-0 h-100 w-100 overflow-hidden bg-dark bg-opacity-50"
      (click)="toggleNavSide(false)"
    ></div>
    <div
      [attr.data-bs-theme]="sideColorMode"
      [@navSideOverlayContent]="{
        value: !navSideOverlayed
          ? 'void'
          : navSideExpanded
            ? widthCategory <= sideOverlayExpandedFullMaxSize
              ? 'expandedFull'
              : 'expanded'
            : 'collapsed'
      }"
      class="side-overlay position-fixed top-0 vh-100 bg-body text-body shadow"
      style="width: var(--bs-w-sidenav)"
    >
      <ng-container *ngTemplateOutlet="navSideContent" />
    </div>
  </div>
}

<ng-template #navSideContent>
  <standard-nav-side
    #navSide
    class="h-100"
    headerStyleClass="px-3 border-bottom h-header"
    startActionsStyleClass="py-3 px-3"
    endActionsStyleClass="py-3 px-3"
  >
    @if (navSideHeaderTemplate || navSideOverlayed) {
      <ng-template #header>
        @if (navSideOverlayed) {
          <div class="row flex-nowrap flex-grow-1 align-items-center">
            @if (navSideHeaderTemplate) {
              <div class="col-auto pe-0">
                <ng-container *ngTemplateOutlet="navSideHeaderTemplate" />
              </div>
            }

            <div class="col-auto ms-auto">
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="toggleNavSide(false)"
              ></button>
            </div>
          </div>
        }

        @if (!navSideOverlayed) {
          <ng-container *ngTemplateOutlet="navSideHeaderTemplate" />
        }
      </ng-template>
    }

    @if (
      navSideStartActionsTemplate ||
      (widthCategory !== undefined &&
        widthCategory <= topStartToSideStartMaxSize &&
        navTopStartActionsTemplate) ||
      (widthCategory !== undefined &&
        widthCategory > MD &&
        navSideStartLargishOnlyActionsTemplate)
    ) {
      <ng-template #startActions>
        @if (
          widthCategory !== undefined &&
          widthCategory > MD &&
          navSideStartLargishOnlyActionsTemplate
        ) {
          <ng-container
            *ngTemplateOutlet="
              navSideStartLargishOnlyActionsTemplate;
              context: { variant: 'side-nav' }
            "
          />
        }

        @if (
          widthCategory !== undefined &&
          widthCategory <= topStartToSideStartMaxSize
        ) {
          <ng-container
            *ngTemplateOutlet="
              navTopStartActionsTemplate;
              context: { variant: 'side-nav' }
            "
          />
        }

        <ng-container
          *ngTemplateOutlet="
            navSideStartActionsTemplate;
            context: { variant: 'side-nav' }
          "
        />
      </ng-template>
    }

    @if (
      navSideEndActionsTemplate ||
      (widthCategory !== undefined &&
        widthCategory <= topEndToSideEndMaxSize &&
        navTopEndActionsTemplate) ||
      (widthCategory !== undefined &&
        widthCategory <= MD &&
        navSideEndSmallishOnlyActionsTemplate)
    ) {
      <ng-template #endActions>
        @if (
          widthCategory !== undefined &&
          widthCategory <= MD &&
          navSideEndSmallishOnlyActionsTemplate
        ) {
          <ng-container
            *ngTemplateOutlet="
              navSideEndSmallishOnlyActionsTemplate;
              context: { variant: 'side-nav' }
            "
          />
        }

        <ng-container
          *ngTemplateOutlet="
            navSideEndActionsTemplate;
            context: { variant: 'side-nav' }
          "
        />

        @if (
          widthCategory !== undefined && widthCategory <= topEndToSideEndMaxSize
        ) {
          <ng-container
            *ngTemplateOutlet="
              navTopEndActionsTemplate;
              context: { variant: 'side-nav' }
            "
          />
        }
      </ng-template>
    }
  </standard-nav-side>
</ng-template>
